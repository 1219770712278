.row-content {
      background-color: $color-bg-grey;
      
      &__content {
            padding: $box-padding-s;
            }
      }


.row-content-split {
      padding-left:0;
      padding-right:0;
      background-color:$color-bg-darkgrey;
      color:white;

      .container {
            display:flex;
            flex-direction:column;
            }

      &--image {
            padding-bottom:52.25%;
            background-repeat: no-repeat;
            background-position: left bottom;
            background-size: 100% auto;
            }

      &:nth-of-type(even) {
            background-color:white;
            color:$color-body;

            .button {
                  color:$color-body;
                  box-shadow:0 0 0 1px $color-primary;

                  &:hover,
                  &:focus,
                  &:visited {
                        color:$color-body;
                        box-shadow:0 0 0 1px $color-body;
                        }

                  }

            &.row-content-split--image {
                  background-position: right bottom;
                  }
            }
      }

.row-content__header {
      font-size:2.0em;
      position: relative;
      display: inline-block;
      letter-spacing:2px;
      line-height:1.1em;
      padding-bottom: $box-padding-s;

      &:before {
            position: absolute;
            content:'';
            bottom:12px;
            height:1px;
            width:70%;
            background-color:$color-primary;
            }

      &:after {
            display: inline-block;
            content:'.';
            color:$color-primary;
            margin-left:5px;
            }
      
      &--highlight {
            color: $color-primary;
            font-size:1.2em;
            margin-bottom:$box-padding-s;
      }
}

.row-content-form {
      &__image {
            max-height:400px;
            }
      }

.row-form {
      &__section {
            background:white;
      }
}

.cta {
      text-align: center;
      background:$color-cta;
      padding:$box-padding-s 0;
      
      &__title {
            font-size: 1.8em;
            font-family: RobotoBold, sans-serif;
            margin-bottom:$box-padding-s;
            }
      
      :link,
      :visited {
            background-color:$color-bg-darkgrey;
            margin:0;
            }
      }


@media screen and (min-width:$screen-xs-max) { //768
      
      .row-content {
      
            &__content {
                  padding: $box-padding-m;
            }
      }
      
      .row-content__header {
            font-size:3.8em;
            line-height:1.1em;
            margin:0 0 $box-padding-s 0;
            padding-bottom: $box-padding-m;

            &:before {
                  position: absolute;
                  content:'';
                  bottom:25px;
                  height:1px;
                  width:70%;
                  background-color:$color-primary;
                  }
            }
      
      .row-content-split {

            .container {
                  flex-direction:row-reverse;
                  flex-wrap: wrap;
                  }
      
            &:nth-of-type(even) .container {
                  flex-direction:row;
                  }
            }
      
      .cta {
            padding:$box-padding-m 0;
            }
            
      }

@media screen and (min-width: $screen-md-min) { // 992

      .row-content-split {

            &--image {
                  padding-bottom:0;
                  background-size: 70% auto;
                  background-repeat: no-repeat;
                  }

            &__section {
                  width:50%;
                  }
            }
      
      .row-content-split__section {
      
            &--image {
                  min-height: 300px;
                  max-height:600px;
                  }

            &--smallimage {
                  min-height: 410px;
                  max-height:410px;
                  }
            }
      
      .cta {
            padding:$box-padding-l 0;
            display: flex;
            justify-content:center;
            
            &__title {
                  display: inline-block;
                  margin-right:$box-padding-s;
                  margin-bottom:0;
                  }
            }
      
      }

@media screen and (min-width: $screen-lg-min) {   // 1200
      
      .row-content {
      
            &__content {
                  padding: $box-padding-l;
                  }
            }
      }
