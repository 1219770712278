html {
  height: 100%;
}

body {
  background-color: white;
  font-size: 16px;
  height: 100%;
  overflow-x:hidden;
  color:$color-body;
  font-family: $font-body;
}

ul, ol {
  padding-left: 1em;
}

a {
  transition: background-color .2s linear;
  color:$color-primary;
  &:hover {
    text-decoration: none;
    color:darken($color-primary, 15%);
  }
}

p {
  line-height:1.7em;
  }

h1,h2,h3,h4,h5,h6 {
  font-family: $headings-font-family;
  font-weight: normal;
}

.body-container {
  display: block; /* Backup for IE9 */

  display: -webkit-flex; /* Safari */
  display: -ms-flex; /* IE */
  display: flex;

  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  & > * {
    width: 100%;
  }
  &__start {
    -webkit-align-self: flex-start;
    -ms-align-self: flex-start;
    align-self: flex-start;
  }
  &__center {
    -webkit-align-self: center;
    -ms-align-self: center;
    align-self: center;
  }
  &__end {
    -webkit-align-self: flex-end;
    -ms-align-self: flex-end;
    align-self: flex-end;
  }
}

.container {
  &--relative {
    position: relative;
  }
  &--xs {
    max-width: 755px;
  }
}


.row {
  &--xs {
    margin-left: -1px;
    margin-right: -1px;
    > [class*=" col-"], > [class^="col-"] {
      padding-left: 1px;
      padding-right: 1px;
    }
  }
}

.title {
  font-family: $headings-font-family;
  font-weight: normal;
  font-size: 24px;
  margin-bottom: 15px;
  margin-top: 0;
  color: $brand-primary;
  &--centered {
    text-align: center;
  }
}
.plain { // used to have titles appear as normal text except for the margin
  font-family: $font-family-base;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 0;
}


.button {
  display: inline-block;
  background-color: transparent;
  padding:15px 80px 15px 40px;
  color:white;
  font-family: $font-body, sans-serif;
  text-transform: uppercase;
  margin:20px 0;
  box-shadow:0 0 0 1px $color-primary;
  transition: box-shadow, 0.3s ease;
  cursor:pointer;
  position: relative;
  
  &:after {
    content:'⟶';
    display: inline-block;
    position: absolute;
    right:30px;
    top:4px;
    font-size:1.5em;
    }
  
  &:hover,
  &:focus,
  &:visited {
    color:white;
    box-shadow:0 0 0 1px white;
    }

  }

.icon-link {
  display: inline-block;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  background-color: $brand-secondary;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  margin: 0 0 0 10px;
  &:hover, &:active, &:focus {
    color: #fff;
    background-color: $brand-primary;
  }
}

.page-header {
  margin: 0 0 0.5em;
  font-size:24px;
}

.heading {
  display: block;
  width:100%;
  clear:both;
  font-size:25px;
  
  &--centered {
    padding:0 15px;
    text-align: center;
    }
  }

.is--clean {
  padding:0 0;
  }

.container {
  width:100%;
  margin:0 auto;
  padding: 0 20px;
  
  }

.image-container {
  overflow: hidden;
  background-size:cover;
  background-position: center center;
  
  & > * {
    //object-fit: cover;
    opacity: 0;
    width:100%;
    //height: 100%;
    }
  }

.user-generated-content {
  
  h2, h3, h4, h4, h6 {
    margin-bottom:0;
    }
  
  p {
    margin-top:0;
    }
  
  }


.wrapper {
  min-height: 100vh;
  
  &.is-animating {
    transition: transform 5s 3s ease-out;
    }
  }


.block-title {
  display: block;

  &--centered {
    width:100%;
    text-align: center;
  }

  &--no-mg {
    margin:0;
  }
}

.sub-title {
  margin-bottom:$box-padding-m;

  &--centered {
    width:100%;
    text-align: center;
  }
}

@media screen and (min-width: $screen-sm-min) {   // 768

  body {
    font-size: 16px;
  }

  .plain { // used to have titles appear as normal text except for the margin
    font-size: 16px;
  }
  
  //.page-header {
  //  margin-top: 60px;
  //}

}

@media screen and (min-width: $screen-md-min) {   // 992
  .heading {
    font-size:35px;
  }
  
  
  .container {
    box-sizing: border-box;
    max-width:1440px;
    padding: 0 20px;
    }
}

@media screen and (min-width: $screen-lg-min) {   // 1200

  body {
    padding-top: 0;
  }

}

.is-visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@media screen and (min-width: $screen-xl-min) { // 1440
  
  .container {
    box-sizing: border-box;
    max-width: 1920px;
    padding: 0 100px;
    }
    
  }