.footer {
  padding:$box-padding-s;
  position: relative;
  font-size:0.8em;

  &__logocontainer {
    max-width:160px;
    margin-bottom:20px;
    }

  &__logo {
    background:$color-bg-darkgrey;
    max-width:100%;
    }

  &__logocontainer:before {
    position: absolute;
    content:'';
    display: block;
    height:130px;
    left:0;
    top:0;
    width:100%;
    background:$color-bg-darkgrey;
    z-index: -1;
    }

    & :link,
    & :visited {
      color:$color-body;
      cursor:pointer;
      }

  }

@media screen and (min-width:$screen-xs-max) { //768
  .footer {
    padding: $box-padding-m;

    &__col {
      float: left;
      }

    &:after {
      display: block;
      content: '';
      clear: both;
      }
    }

  .footer__col {

    &--small {
      width:160px;
      }

    &--wide {
      margin-left:40px;
      padding-top:100px;
      width: calc(100% - 200px);
      }
    }
  }

@media screen and (min-width: $screen-md-min) { //992

  .footer {
    padding:$box-padding-m $box-padding-l;

    }
  }