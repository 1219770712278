.main-header {
  color: #fff !important;
  background:black;
  height:75vh;
  position: relative;

  .title {
    color: #fff;
    font-size: 2em;
    text-shadow: 2px 2px 3px rgba(0,0,0,0.5);
  }

  &__panel {
    margin: 15px 0;
    padding: 15px;
    max-width: 420px;
    background-color: rgba($brand-primary, .65);
  }

  &__gallery {
    position: relative;
    z-index: 1;
    > * {
      position: relative;
      z-index: 1;
    }
  }

  &--has-image {
    position: relative;
    overflow:hidden;
  }

  &__carousel {
    width:100%;
    position: absolute;
    height:100%;
    left:0;
    top:0;
  }

  &__image {
    position: absolute;
    width:100%;
    height:100%;
  }

  &__image:before {
    position: absolute;
    content:'';
    background:rgba(0,0,0,0.25);
    height:100%;
    width:100%;
  }

  &--page {
    height:55vh;
  }
}

.caption {
  position: absolute;
  bottom:10px;
  display: block;
  color:$color-body;
  width:100%;
  max-width:1920px;
  left:50%;
  transform:translateX(-50%);
  padding:0 $box-padding-m;

  &__title,
  &__content {
    max-width:100%;
    color:white;
  }

  &__title {
    font-size:2.0em;
    position: relative;
    display: inline-block;
    letter-spacing:2px;
    line-height:1.1em;
    padding-bottom: $box-padding-s;

    &:before {
      position: absolute;
      content:'';
      bottom:12px;
      height:1px;
      width:70%;
      background-color:$color-primary;
      }

    &:after {
      display: inline-block;
      content:'.';
      color:$color-primary;
      margin-left:5px;
      }
  }

}

.action {

  &__bar {
    background: $color-primary;
    position: absolute;
    width: 100%;
    color: white;
    padding: 10px 25px;
    text-align: center;
    left: 0;
    top: $menuh;
    font-size: 18px;
    font-weight: bold;
    }

  &__button {
    background: $color-primary;
    position: absolute;
    //display: flex;
    display: none;
    flex-direction: column;
    width:120px;
    height:120px;
    border-radius:50%;
    top:165px;
    right:20px;
    text-align: center;
    justify-content: center;
    font-weight: normal;
    font-size: 13px;
    }
  }


@media screen and (min-width:$screen-xs-min) { // 480

  .main-header--product {
    .caption {
      bottom:10px;
    }
  }
}

@media screen and (min-width:$screen-xs-max) { // 768

  .caption {
    //  bottom:240px;
    padding:0 $box-padding-m 0 70px;

    &__title {
      font-size:3.4em;
      }
  }

  .main-header {
    &--product {
      max-height:450px;
    }
  }

}


@media screen and (min-width: $screen-md-min) { // 992


  .main-header {

    .title {
      font-size: 3em;
    }
  }

  .caption {
    padding:0 $box-padding-m 0 70px;

    &--main {
      left:0;
    }

    &--page {
      padding: 0 $box-padding-m;
    }

    &__title,
    &__content {
      max-width:900px;
    }

    &__content {
      font-size:1.7em;
    }
  }
  .main-header {
    padding-bottom: $box-padding-s;
    padding-top: 15px;
    position: relative;
    z-index: 10;

    &__caption {
      bottom:0;
    }
    &--has-image {
      padding-bottom: 0;
    }
    &__title, &__panel {
      margin: $box-padding-m 0;
    }

    &--has-gallery {
      z-index: 10;
      .main-header__caption {
        bottom:230px;
      }
    }
  }

  .action {

    &__bar {
      top: $menuheight;
      font-size:25px;
      }

    &__button {
      width:200px;
      height:200px;
      top:auto;
      bottom:50px;
      font-weight: bold;
      font-size: 20px;
      }
    }

}

@media screen and (min-width: $screen-lg-min) {   // 1200

  .main-header--product .caption {
    bottom:$box-padding-m;
  }

  .caption {
    padding:0 $box-padding-l 0 100px;
    }
}


@media screen and (min-width: 1440px) {   // 1440

  .caption {
    padding:0 $box-padding-l 0 200px;
    }
}



@media screen and (max-height:900px) and (min-width: $screen-md-min) { // 850 hoog
  .main-header {
    height: 80vh;
  }
  .caption {
    // bottom:100px;
  }

}
