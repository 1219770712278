.box-form-wrapper {

    .alert:first-of-type {
        margin-top:$box-padding-s;
    }
}

.form {
    padding:$box-padding-s $box-padding-s 0 0;

    &__fieldset {
        margin:0;
        padding:0;
        border:none;
    }

    &-group {
        display: block;
        position: relative;
        margin-bottom:$box-padding-s;
    }

    &__btn {
        border:none;
    }
}

.form-group {

    &__label {
        display: block;
    }

    &__field {
        display: block;
        border:1px solid $color-border;
    }

    &__field--input,
    &__field--textarea {
        width:100%;
        padding:$box-padding-half;
        border-radius:0;
        box-shadow:none;
        color:$color-body;
        font-size:16px;

        &:focus {
            border:1px solid $color-primary;
            box-shadow:none;
        }
    }

    &__field--input {
        height:55px;
        border-radius:0;
        box-shadow:none;
    }

    &__field--textarea {
        min-height:150px;
    }
}

.form-field-group {

    &__heading {
        font-family: 'RobotoBold' serif;
        font-weight: bold;
        color:$color-body;
        font-size:16px;
        margin-bottom:5px;
        border:none;
        margin-top:15px;
    }

}

.form-group--radio,
.form-group--checkbox {

    margin:0;

    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;


    .form-group__label{
        font-weight: normal;
        text-transform: none;
        letter-spacing: 0;
        font-size:16px;
        cursor:pointer;
        min-height:30px;
        padding:5px 0;
        order:1;
        position: relative;
    }

    .form-group__label:before {
        content:'';
        display: block;
        width:20px;
        height:20px;
        border:4px solid $color-bg-grey;
        background:$color-bg-grey;
        position: absolute;
        left:-28px;
        top:5px;
        box-shadow: 0 0 0 1px $color-border;


        *:checked + & {
            background:$color-secondary;
        }

        *:focus + & {
            box-shadow: 0 0 0 1px $color-secondary;
        }
    }
}

.form-group--radio {
    .form-group__label:before {
        border-radius:50%;
    }
}

.box-newsletter {
    background:darken($color-bg-grey, 5%);
    padding-top:20px;
    padding-bottom:20px;

}

.mc_embed_signup {
    $color-response-base: #3498DB;
    $color-response-error: #E74C3C;
    $color-response-success: #2ECC71;

    width:100%;

    &__header {
        margin-bottom:0;
        margin-top:0;
        padding:0;
    }

    &__subtext {
        margin-top:0;
        margin-bottom:20px;
    }

    .mc-field-group {
        display: block;

        &__label {
            display: block;
            font-family: 'RobotoBold', serif;
            font-weight:normal;
        }

        &__input {
            display: block;
            font-family: 'RobotoLight', serif;
            font-weight:normal;
            width:100%;
            height:40px;
            border: 1px solid #e6e6e6;
            padding:10px;
        }

        &__input.mce_inline_error {
            border-color:lighten($color-response-error, 20%) !important;
        }

        &__input:focus {
            border: 1px solid $color-primary;
        }
    }

    div.mce_inline_error {
        background:lighten($color-response-error, 35%) !important;
        border:1px solid lighten($color-response-error, 30%) !important;
        color:$color-body !important;
        margin-top:20px !important;
        font-weight: normal !important;
        padding:10px !important;
    }
    .response {

        display: block;
        padding:10px;
        background:lighten($color-response-base, 40%);
        border:1px solid lighten($color-response-base, 30%);
        margin-bottom:20px;

        &.mce-success-response {
            background:lighten($color-response-success, 40%);
            border:1px solid lighten($color-response-success, 30%);
        }

        &.mce-error-response {
            background:lighten($color-response-error, 35%);
            border:1px solid lighten($color-response-error, 30%);
        }

    }

}

@media screen and (min-width: $screen-sm-min) { // 768

    .mc-field-group-container {
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 0 1fr;
        grid-gap: 10px;

        .button {
            margin-top: 29px;
            height: 38px;
        }
    }

}