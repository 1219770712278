.menu-wrapper {
    position: fixed;
    margin-right: auto;
    margin-left: auto;
    overflow: visible;
    z-index: 999;
    height:$menuh;
    width:101%;
    background-color:transparent;
    
    &--is-scrolled {
        transition: height .3s linear;
        background-color:$menu-bg-color-scrolled;
        }
    }

.menu-bar {
    font-family: $font-body;
    height: $menuh;
    transition: none;
    
    &__primary {
        height: $menuh;
        position: relative;
        z-index: 99;
        }
    &__secondary {
        display: none;
        height: $menuh;
        }
    }

.logo-wrapper {
    display: none;
    width: 330px;
    position: absolute;
    top: 0;
    margin:0;
    &__logo {
        max-width: 100%;
        height: auto;
        }
    }

.text-logo-wrapper {
    position: absolute;
    padding: 10px 135px 10px 0;
    left: 0;
    top: 0;
    width: 100%;
    text-align: left;
    &__logo {
        max-width: 100%;
        max-height:55px;
        }
    }

.main-menu {
    display: none;
    position: absolute;
    left: -20px;
    top: $menuh;
    width: 100vw;
    background-color: $color-bg-darkgrey;
    max-height: calc( 100vh - #{$menuh});
    height: calc( 100vh - #{$menuh});
    overflow-y: auto;
    z-index: 100;
    
    .fa:before {
        color:white;
        }
    
    &__item {
        position: relative;
        white-space: nowrap;
        &--has-children {
            :link {
                padding-right: 75px;
                }
            }
        :link,
        :visited {
            color: white;
            display: block;
            padding: 25px 20px;
            text-align: center;
            height: 70px;
            font-size:18px;
            &:hover {
                text-decoration: none;
                color: $color-primary;
                }
            }
        }
    
    &.is-visible {
        display: block;
        }

    &__items {
        position: absolute;
        height:300px;
        top:40%;
        width:100%;
        transform:translateY(-50%);
        }
    }

.sub-menu {

    display: none;
    background:transparent;
    
    &-toggle {
        cursor: pointer;
        position: absolute;
        width: 50px;
        height: 50px;
        line-height: 50px;
        top: 0;
        right: 15px;
        z-index: 10;
        text-align: center;
        &--is-active {
            .fa {
                display: inline-block;
                transform: rotate(180deg);
                }
            }
        }
    
    &__item {
        > :link {
            color:$color-body;
            display: block;
            padding: 15px 20px;
            font-weight: normal;
            &:hover {
                text-decoration: none;
                color:$color-primary;
                }
            }
        }
    }

.menu-button {
    z-index: 100;
    position: absolute;
    right: 0;
    top: 0;
    height: $menuh;
    width: 150px;
    line-height: $menuh;
    text-align: center;
    cursor: pointer;
    user-select: none;
    &__item--close {
        display: none;
        }
    &.menu-button--is-active {
        .menu-button__item--hamburger {
            display: none;
            }
        .menu-button__item--close {
            display: inline;
            }
        }
    &__icon {
        display: inline;
        }
    
    &__text {
        color:white;
        display:inline-block;
        font-size: 19px;
        font-family: 'RobotoLight', sans-serif;
        font-weight:bold;
        padding-right:10px;
        cursor:pointer;
        text-transform: uppercase;
        }
    }

.phone-button {
    z-index: 100;
    position: absolute;
    right: 0;
    top: 0;
    height: $menuh;
    width: $menuh;
    text-align: center;
    line-height: $menuh;
    color: #fff;
    font-size: 24px;
    }

.lang {
    display: inline-block;
    margin-right: 6px;
    width: 16px;
    height: 12px;

    &--nl {
        background: url('/assets/img/flag_nl.jpg') center center no-repeat;
        background-size:100% 100%;
        }

    &--de {
        background: url('/assets/img/flag_de.jpg') center center no-repeat;
        background-size:100% 100%;
        }

    &--en {
        background: url('/assets/img/flag_en.jpg') center center no-repeat;
        background-size:100% 100%;
        }
    }

.langswitch {
    
    .sub-menu-toggle {
        display: inline-block;
        top:10px;
        font-size:15px;
        }

    &__item,
    :link,
    :visited {
        position: relative;
        background:rgba(255,255,255,0.1);
        }

    &__item:hover,
    :link:hover,
    :visited:hover {
        position: relative;
        background:rgba(255,255,255,0.2);
        }

    .sub-menu__item :link,
    .sub-menu__item :visited {
        height:50px;
        }
    }


@media screen and (max-width: $screen-md-max) { // 1200
    .menu-wrapper {
        .container {
            width:101%;
            }
        }
    }

@media screen and (min-width: $screen-md-min) {  // 992
    $itemsheight: 40px;
    $toppadd: calc((#{$menuheight} - 40px) / 2);
    
    .menu-wrapper {
        position:absolute;
        left:50%;
        transform:translateX(-50%);
        overflow: visible;
        height: $menuheight;
        transition: top .3s linear;
        width:101%;
        }
    
    .menu-button {
        display: none;
        }
    
    .menu-bar {
        height:$itemsheight;
        padding-top:$toppadd;
        max-width:calc(100% - 330px);
        float:right;
        
        &__primary {
            padding: 0 0 0 30px;
            height:$itemsheight;
            }
        &__secondary {
            display: block;
            }
        }
    
    .logo-wrapper {
        display: block;
        float:left;
        position: relative;
        &__logo {
            max-height:100px;
            margin-top:15px;
            }
        }
    
    .text-logo-wrapper {
        padding-left: 10px;
        padding-right: 10px;
        width: auto;
        }
    
    .main-menu {
        border: none;
        position: static;
        display: block !important;
        float: right;
        height: auto;
        background-color: transparent;
        width: 100%;
        overflow: visible;
        
        &__items {
            position: relative;
            height:auto;
            top:auto;
            width:auto;
            transform:none;

            display: block;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            text-align: right;
            }
        &__item {
            float: left;
            flex-grow: 1;
            text-align: center;
            border: none;
            cursor:pointer;
    
            :link,
            :visited {
                position: relative;
                color: white;
                display: block;
                padding: 0 10px;
                line-height: 40px;
                height: 40px;
                font-size:16px;
                &:hover,
                &:focus {
                    color: $color-cta-dark;
                    background-color: transparent;
                    }
                }
            &--is-active {
                > :link {
                    color: $color-primary;
                    background-color: transparent;
                    &:hover {
                        &:after {
                            border-bottom: 3px solid $color-primary;
                            }
                        }
                    }
                }
            
            &--is-highlighted {
                font-family: 'RobotoLight', serif;
                cursor:pointer;
                //min-width:150px;
                white-space: nowrap;
                background-color: $color-primary;
                margin-left:10px;

                > :link {
                    padding-left:15px;
                    padding-right:15px;
                    }
                }
            &--is-highlighted :link:hover,
            &--is-highlighted :link:focus,
            &--is-highlighted :visited:hover {
                color:white;
                background-color: $color-cta-dark;
                }
            &--has-children {
                position: relative;
                &:hover {
                    .sub-menu {
                        opacity: 1;
                        visibility: visible;
                        transform: none;
                        }
                    }
                }
            
            }
        }
    
    .main-menu {
        .sub-menu {
            width: auto;
            position: absolute;
            left: 0;
            top: 100%;
            display: block !important;
            opacity: 0;
            visibility: hidden;
            transform: translateY(15px);
            transition: all .2s ease-out;
            min-width: 100%;
            &-toggle {
                display: none;
                }
            }
        
        &__item--has-children:last-child {
            .sub-menu {
                left:auto;
                right:0;
                }
            }
        }
    
    .sub-menu {
        float: none;
        &__item {
            > a{
                padding: 15px;
                text-align: left;
                white-space: nowrap;
                }
            }
        }
        
    
    
    .menu-wrapper--is-scrolled {
        $itemsheight: 40px;
        $menuheight: 100px;
        $toppadd: calc((#{$menuheight} - 40px) / 2);
        
        height:$menuheight;
        position: fixed;
        transition: top .3s linear;
        animation: scrollIntoView 0.5s ease-in;
        
        .menu-bar {
            padding-top:$toppadd;
            }
        
        .logo-wrapper__logo {
            max-height:75px;
            }
    
        .main-menu__item {
            :link,
            :visited {
                color:white;

                &:hover,
                &:focus {
                    color:$color-primary;
                }
            }
            &--is-highlighted :link:hover,
            &--is-highlighted :link:focus,
            &--is-highlighted :visited:hover {
                color:white;
                background:$color-cta-dark;
            }
        }

        }

    .langswitch {

        &__item:hover,
        :link:hover,
        :visited:hover {
            background:rgba(255,255,255,0.2);
            }

        .sub-menu__item :link,
        .sub-menu__item :visited {
            height:auto;
            }
        }

    }

@keyframes scrollIntoView {
    0% {
        top:-$menuheight;
        }
    100% {
        top:0;
        }
    }