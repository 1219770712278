.page {
    
    &-title {
        margin-bottom:0;
        }
    }


.img-overflow-container {
    
    width:100vw;
    margin:-30px 0 30px -20px;
    height:250px;
    overflow:hidden;
    
    & > * {
        width:100%;
        height:100%;
        object-fit:cover;
        }
    }

.box-table {
    width:100%;
    max-width:350px;
    
    &__row {
        display: flex;
        flex-direction: row;
        }
    
    &__item {
        width:50%;
        }
    }


@media screen and (min-width:$screen-xs-max) {
    .img-overflow-container {
        height:350px;
        }
    }

@media screen and (min-width:$screen-md-min) {
    
    .img-overflow-container {
        width: 100%;
        margin: -120px 0 0;
        height: 400px;
        overflow: hidden;
        }
    }